(function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
})(
    window,
    document,
    "script",
    "dataLayer",
    import.meta.env.VITE_NODE_ENV === "prod" || import.meta.env.VITE_NODE_ENV === "production"
        ? "GTM-MPVX3X3"
        : import.meta.env.VITE_REGION == "EU"
        ? "GTM-NK854KGG"
        : "GTM-NK854KGG1" // invalid GTM to not track .com staging
);
