(function () {
    // Create the noscript element
    var noscript = document.createElement("noscript");
    var tag =
        import.meta.env.VITE_NODE_ENV === "prod" || import.meta.env.VITE_NODE_ENV === "production"
            ? "GTM-MPVX3X3"
            : "GTM-NK854KGG";
    // Set its innerHTML to the iframe
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${tag}" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager"></iframe>`;

    // Append the noscript element to the body
    document.body.appendChild(noscript);
})();
