import type { AxiosError } from "axios";
import type { User } from "firebase/auth";
import type {
    HeadDoesEmailExistParams,
    CurrentUserData,
    GetValidateMobileParams,
    GetValidateMobileData,
    PostCreateLiveUserData,
    PostCreateDemoUserData,
    PostResendEmailData,
    PostSendResetPasswordEmailData,
    PostSetupLegacyUser,
    PutEditDemoUserData,
    PutEditLiveUserData,
    PutSetupLiveUserData,
    GetSumsubToken,
    sendOtpCodeParams,
    verifyOtpCodeParams,
    EUPostCreateLiveUserData,
    EUPutEditLiveUserData,
    EUAddAcknowledgementData,
    TradingOptionsLookups,
    ElectiveProfessionalData,
    EUPutSetupLiveUserData,
    PostEnableOtpOnLogin,
    UserMetaData,
    UserCoreData,
} from "./type";
import type { SSOLoginRequest } from "../auth/type";

import { axios } from "@/libs/axios";
import { capitalizeFormatter, nonNullishObjectFormatter } from "@/libs/formatter";
import { firebaseAuth, firebaseUtils } from "@/libs/firebaseAuth";
import { isEU } from "@/libs/processEnv";

export const headCheckEmailExists = async (params: HeadDoesEmailExistParams): Promise<boolean> => {
    try {
        await axios.head("/users", { params });

        return true;
    } catch (e) {
        /**
         * This /HEAD checks returns 400 if no matching email is found.
         */
        return (e as AxiosError).code === "400";
    }
};

export const getCurrentUserMetadata = async ({
    email,
    phoneNumber,
    photoURL,
    emailVerified,
}: User): Promise<CurrentUserData> => {
    const { data: user } = await axios.get("/users");

    return {
        ...user,
        emailVerified,
        email: email ?? undefined,
        phoneNumber: phoneNumber ?? user?.mobilePhone ?? undefined,
        photoURL: photoURL ?? undefined,
        firstName: capitalizeFormatter(user?.firstName),
        lastName: capitalizeFormatter(user?.lastName),
        fullName:
            user?.firstName !== undefined && user?.firstName.length > 1
                ? capitalizeFormatter(`${user?.firstName} ${user?.lastName}`)
                : undefined,
        electiveProfessionalStatus: user?.electiveProfessionalStatus ?? 0,
        status: user?.status ?? "",
        infractionStatus: user?.infractionStatus ?? "",
        otpEnabled: user?.otpEnabled ?? false,
        otpOptions: user?.otpTarget ?? "BOTH",
    } as CurrentUserData;
};

export const LogInWithGoogle = async (data: SSOLoginRequest): Promise<boolean> => {
    await firebaseAuth.setPersistence(firebaseUtils.browserLocalPersistence);
    await axios.post("/users/signInViaSSO", data);
    return true;
};

export const getUserDepositedStatus = async () => {
    const { data: isLiveUserDeposited } = await axios.get<boolean>("/accounts/live/dashboard-deposit-status");

    return isLiveUserDeposited;
};

export const getValidateUserMobilePhone = async ({ mobilePhone }: GetValidateMobileParams) => {
    const { data } = await axios.get<GetValidateMobileData>("/users/validate-mobile", {
        params: { mobile: mobilePhone },
    });

    return data;
};

export const getCurrentUserGenerateSumsubToken = async () => {
    const { data } = await axios.get<GetSumsubToken>("/accounts/live/sumsub-token");

    return data;
};

export const getCurrentUserCreateApplicant = async () => {
    const { data } = await axios.get("/accounts/live/sumsub-applicant");

    return data;
};

export const postCreateNewLiveUser = async (data: PostCreateLiveUserData): Promise<void> => {
    await axios.post<PostCreateLiveUserData>("/users/live", data);
};

export const postEUCreateNewLiveUser = async (data: EUPostCreateLiveUserData): Promise<void> => {
    await axios.post<EUPostCreateLiveUserData>("/users/eu/live", data);
};

export const putEditLiveUser = async (data: PutEditLiveUserData): Promise<void> => {
    const nonNullishData = nonNullishObjectFormatter(data);

    await axios.put<PutEditLiveUserData>("/users/live", nonNullishData);
};

export const putEUDemoToLiveEditLiveUser = async (data: PutEditLiveUserData): Promise<void> => {
    const nonNullishData = nonNullishObjectFormatter(data);

    await axios.put<PutEditLiveUserData>("/users/eu/demo/convert-to-live", nonNullishData);
};

export const putEUEditLiveUser = async (data: EUPutEditLiveUserData): Promise<void> => {
    await axios.put<EUPutEditLiveUserData>("/users/eu/live", data)
};

export const EUAddAcknowledgement = async (data: EUAddAcknowledgementData): Promise<void> => {
    await axios.put<EUAddAcknowledgementData>("/users/eu/live/AddclientAcknowledgement", data);
};

export const putSetupLiveUser = async (data: PutSetupLiveUserData): Promise<void> => {
    await axios.put("/users/live/setup", data);
};
export const putEUSetupLiveUser = async (data: EUPutSetupLiveUserData): Promise<void> => {
    await axios.put("/users/eu/live/setup", data);
};

export const addEmailVerifiedTag = async (data = {}): Promise<void> => {
    await axios.post("/users/email-verified-tag", data);
};

export const putAcceptEulaLiveUser = async (): Promise<void> => {
    await axios.put("/users/live/eula");
};

export const postEmailResetPassword = async (data: PostSendResetPasswordEmailData): Promise<void> => {
    await axios.post("/users/reset-password", data);
};

export const postResendEmail = async (data: PostResendEmailData): Promise<void> => {
    await axios.post("/users/resend-email", data);
};

export const postCreateNewDemoUser = async (data: Pick<UserCoreData, "email" | "country" > &
    Pick<UserMetaData, "firstName" | "lastName"> & { "region": string}
): Promise<void> => {
    await axios.post<PostCreateDemoUserData>("/users/demo", data);
};

export const putEditDemoUser = async (data: PutEditDemoUserData): Promise<void> => {
    await axios.put<PutEditDemoUserData>("/users/demo", data);
};

export const postSetupLegacyUser = async (data: PostSetupLegacyUser): Promise<void> => {
    await axios.post<PostSetupLegacyUser>("/users/invite", data);
};
export const sendOTP = async ({ type, target }: sendOtpCodeParams): Promise<void> => {
    await axios.get<sendOtpCodeParams>("/users/send-otp", {
        params: { otpType: type, otpTarget: target },
    });
};
export const verifyOTP = async ({ otp, type }: verifyOtpCodeParams): Promise<boolean> => {
    try {
        const { data } = await axios.get<boolean>("/users/verify-otp", {
            params: { otp, otpType: type },
        });
        return data;
    } catch (e) {
        /**
         * This /HEAD checks returns 400 if no matching email is found.
         */
        return false;
    }
};

export const getLookupItems = async (): Promise<TradingOptionsLookups[]> => {
    const { data } = await axios.get<TradingOptionsLookups[]>("/eu/lookups");
    return data;
};

export const postEUElectiveProfessionalData = async (data: ElectiveProfessionalData) => {
    const formData = new FormData();

    formData.append("AuthId", data.AuthId);
    formData.append("TradingActivityInd", data.TradingActivityInd.toString() ?? "false");
    formData.append("FinancialServicesInd", data.FinancialServicesInd.toString() ?? "false");
    formData.append("InstrumentPortfolioInd", data.InstrumentPortfolioInd.toString() ?? "false");
    formData.append("TradingActivityProof", data.TradingActivityProof ?? "");
    formData.append("FinancialServicesProof", data.FinancialServicesProof ?? "");
    formData.append("InstrumentPortfolioProof", data.InstrumentPortfolioProof ?? "");
    formData.append("PositionLookupId", data.PositionLookupId.toString() ?? "0");
    formData.append("FinancialSectorLookupId", data.FinancialSectorLookupId.toString() ?? "0");
    formData.append("ClientRiskAck", data.ClientRiskAck?.toString() ?? "false");
    formData.append("ConfirmInformChanges", data.ConfirmInformChanges?.toString() ?? "false");
    formData.append("ConfirmFormAccuracy", data.ConfirmFormAccuracy?.toString() ?? "false");
    formData.append("TTCAagree", data.TTCAagree?.toString() ?? "false");

    await axios.post("/users/eu/elective-professional", formData);
};

export const getElectiveProfessionalStatus = async () => {
    const requestStatus = await axios.get("/users/eu/elective-professional-status");
    return requestStatus.data ? requestStatus.data : 0;
};

export const postEnableOtpOnLogin = async (data: PostEnableOtpOnLogin) => {
    await axios.post("/users/otp-settings", data);
};

export const manuallyApproveSumsub = async (authId: string | undefined) => {
    const data = {
        "externalUserId": authId,
        "levelName": isEU() ? "kinectroDev" : "nStepDev",
        "reviewResult": {
            "reviewAnswer": "GREEN",
        },
    };
    await axios.post(isEU() ? "/accounts/live/eu/sumsub-callback" : "/accounts/live/sumsub-callback", data);
};

export const getRecaptchaKey = async () => {
    const { data } = await axios.get(`/users/captcha-key`);
    return data;
};
