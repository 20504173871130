import type { AppLayoutProps, AppLayoutFooterProps } from "./type";
import type { InheritableElementProps } from "@/types";

import { tx } from "@/libs/tailwindMerge";
import { isEU } from "@/libs/processEnv";

import { useDevInfo } from "@/query/dev";

import { AprCard } from "@/components/Card";
import { ToggleTheme } from "@/components/ThemeContext/ToggleTheme";

export const AppLayoutCopyright = ({ className, ...props }: InheritableElementProps<"div">) => {
    const { devInfo } = useDevInfo();
    const date = new Date().getFullYear();
    return (
        <div className={tx("text-2xs text-foreground", className)} {...props}>
            {isEU() && `Copyright © Afterprime ${date}. All rights reserved. v${devInfo.version}`}
            {!isEU() && `Copyright © Afterprime. All rights reserved. v${devInfo.version}`}
        </div>
    );
};

export const AppLayoutFooter = ({ className, showfooter = "false", ...props }: AppLayoutFooterProps) => {
    return showfooter === "true" ? (
        <AprCard
            as="footer"
            className={tx("mt-auto rounded-none border-none bg-white/25 p-0 dark:bg-black/25", className)}
            {...props}
        >
            <div className="container mx-auto space-y-4 px-6 py-8 text-xs text-black/75 dark:text-foreground">
                <h3 className="text-sm font-semibold">Customer Notice</h3>
                {!isEU() ? (
                    <>
                        <p>
                            Trading CFDs and FX is high risk and not suitable for all investors. Losses can exceed your
                            initial investment. Any Information or advice contained on this website is general in nature
                            and has been prepared without taking into account your objectives, financial situation, or
                            needs. Our&nbsp;
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://afterprime.com/legal/"
                                className="text-themeColor/88 hover:text-themeColor/68 underline"
                            >
                                Risk Disclosures and Legal documents
                            </a>
                            &nbsp;should be considered before deciding to enter into any derivative transactions.
                        </p>
                        <p>
                            The information on this site is not directed at residents of the following countries:
                            Australia, United States, China, New Zealand, Japan, and is not intended for distribution
                            to, or use by, any person in any country or jurisdiction where such distribution or use
                            would be contrary to local law or regulation.
                        </p>
                        <p>
                            Afterprime Ltd (Seychelles company registration number 8426189-1) is a Securities Dealer,
                            authorised by the Financial Services Authority (FSA) with licence number SD057. The
                            registered office of Afterprime Ltd is 9A CT House, 2nd floor, Providence, Mahé, Seychelles.
                        </p>
                        <p>
                            Payment processing performed by SC Afterprime Limited, a Cyprus incorporated company with
                            registration number HE 615319 and registered office at Archiepiskopou Makariou ΙΙΙ, 160, 1st
                            Floor, 3026, Limassol, Cyprus.
                        </p>
                        <p>The entity above is duly authorised to operate under the Afterprime brand and trademarks.</p>
                    </>
                ) : (
                    <>
                        <p>
                            Afterprime is operated by Afterprime Europe Limited ( ex H.C.F.S High Capital Financial
                            Services Limited) , a Cyprus Investment Firm authorised and supervised by the Cyprus
                            Securities and Exchange Commission (CySEC ) with license number 368/18 , with a registration
                            number HE 360438 and a registered address at Modestou Paneli ,4 Mesa Geitonia, 4003,
                            Limassol, Cyprus.
                        </p>
                        <p>
                            Risk Warning: CFDs are complex instruments and come with a high risk of losing money rapidly
                            due to leverage. 67% of retail investors&apos; accounts lose money when trading CFDs with
                            this provider. You should consider whether you understand how CFDs work and whether you can
                            afford to take the high risk of losing your money.
                        </p>
                        <p>
                            The information on this website is not intended to be an inducement, offer, or solicitation
                            to any person in any country or jurisdiction where such distribution or use would be
                            contrary to local law or regulation.
                        </p>
                        <p>The entity above is duly authorised to operate under the Afterprime brand and trademarks.</p>
                        <p>
                            © Copyright {new Date().getFullYear()} Afterprime. All rights reserved. All trademarks,
                            service marks, trade names, trade dress, product names and logos appearing on the site are
                            the property of their respective owners.
                        </p>
                    </>
                )}
                {!isEU() && <AppLayoutCopyright className="text-black/75 dark:text-foreground" />}
            </div>
        </AprCard>
    ) : null;
};

/**
 * `AppLayout` provides the default background image default for all Afterprime pages.
 *
 * Refer to:
 * - `AuthLayout` for the common layout for Demo, Live, & Email auth flows.
 * - `DashboardLayout` for common layout of the Client Dashboard.
 *
 * Extends this list if needed.
 */
export const AppLayout = ({ className, children, showThemeIcon = false, ...props }: AppLayoutProps) => {
    return (
        <div
            className={tx("relative flex h-full w-full flex-1 flex-col overflow-x-auto overflow-y-visible", className)}
            {...props}
        >
            {children}
            {showThemeIcon && (
                <AprCard className={tx("fixed right-2 top-4", "pointer-events-auto z-50 cursor-move p-1")} {...props}>
                    <ToggleTheme />
                </AprCard>
            )}
        </div>
    );
};

AppLayout.Copyright = AppLayoutCopyright;
AppLayout.Footer = AppLayoutFooter;
