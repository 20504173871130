import type { AxiosError } from "axios";
import type { UserCredential } from "firebase/auth";
import type { LoginCredentials, SSOLoginRequest } from "@/query/auth/type";

import ls from "localstorage-slim";
import { onAuthStateChanged } from "firebase/auth";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { axios } from "@/libs/axios";
import { firebaseAuth } from "@/libs/firebaseAuth";

import { useEffectOnce } from "@/hooks/useEffectOnce";

import { useEmailVerifiedTag, useSetupLiveUserAuth, useUserAttribution } from "@/query/users";
import { LogInWithGoogle } from "@/query/users/api";

import { getAuthUser, logInWithEmailAndPassword, logInWithEmailLink, logout, updatePassword } from "./api";

const PRELOAD_KEY = "preload-able";

/** Auth queries */
export const useUserAuth = () => {
    const queryClient = useQueryClient();

    /**
     * Local storage flag to notify if the user is pre-signed-in to speed up loading auth state.
     * Reference: https://www.youtube.com/watch?v=NwY6jkohseg&t=1309s
     */
    const preSignedIn = ls.get(PRELOAD_KEY);

    const {
        data: auth,
        isLoading,
        ...props
    } = useQuery(["auth"], {
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: true,
        refetchIntervalInBackground: true,
        refetchInterval: (data) => (data || data === null ? 60 * 1000 : 60),
        queryFn: getAuthUser,
        onSuccess: async (userAuth) => {
            const force = ls.get("role-updated") === "change" ? true : false;
            const accessToken = await userAuth?.getIdToken(force);
            ls.remove("role-updated");
            if (accessToken && userAuth?.emailVerified) {
                /** Reconfigure axios with access token. */
                axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
            }
        },
    });


    useEffectOnce(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            if (user) {
                ls.set(PRELOAD_KEY, true);
                queryClient.refetchQueries(["auth"]);
            } else {
                ls.remove(PRELOAD_KEY);
            }
        })
        return () => {unsubscribe()};
});
    

    /**
     * Firebase auth is actually trinary.
     * Valid (definitely logged in) > Null (definitely logged out) > Undefined (unknown).
     *
     * Keep this in mind when using auth as an object to validate user's auth state.
     * useCurrentUser() offers more details but require an additional GET from our server.
     *
     * tl,dr: if (!auth) {} may not work as well as you think in many cases.
     */
    return { auth, isLoading: !!(preSignedIn && !auth) || isLoading, ...props };
};

export const useLoginWithEmailAndPassword = () => {
    const queryClient = useQueryClient();
    const { mutate: loginUser, ...props } = useMutation<UserCredential, AxiosError, LoginCredentials>({
        mutationFn: logInWithEmailAndPassword,
        onSuccess: () => {
            queryClient.refetchQueries(["auth"]),
            queryClient.refetchQueries(["user"])
        }
    });

    return { loginUser, ...props };
};
export const useGoogleSSO = () => {
    const attribution = useUserAttribution();
    const { mutate: googleLoginUser, ...props } = useMutation<boolean, AxiosError, SSOLoginRequest>({
        mutationFn: (data: SSOLoginRequest) => LogInWithGoogle({ ...data, ...attribution }),
        onSuccess: async () => {
            const fbAuth = getAuthUser();
            const token = fbAuth && (await fbAuth.getIdToken(true));
            if (token && fbAuth?.emailVerified) {
                /** Reconfigure axios with access token. */
                axios.defaults.headers.Authorization = `Bearer ${token}`;
            }
        },
    });

    return { googleLoginUser, ...props };
};

export const useLoginWithEmailLink = () => {
    const queryClient = useQueryClient();

    const {
        mutateAsync: setupLiveUserAsync,
        isLoading: isSetupLiveUserLoading,
        isError: isSetupLiveUserError,
    } = useSetupLiveUserAuth();
    const {
        mutateAsync: loginUserAsync,
        isLoading: isLoginWithEmailPasswordLoading,
        isError: isLoginWithEmailError,
    } = useLoginWithEmailAndPassword();
    const { mutateAsync: emailVerifiedTagAsync } = useEmailVerifiedTag();

    const {
        mutate: loginUserWithEmail,
        isLoading,
        isError,
        ...props
    } = useMutation({
        mutationFn: logInWithEmailLink,
        onSuccess: async ({ user }, { email }) => {
            await queryClient.refetchQueries(["auth"]);
            /** Restore password if there's any. */
            const password = ls.get(email, { decrypt: true }) as string;
            const mobilePhone = ls.get(`${email}-EU`, { decrypt: true }) as string;

            if (!password || !user?.emailVerified) {
                return;
            }

            ls.remove(email);
            await setupLiveUserAsync({ password, isEmailVerified: true, mobilePhone });
            await loginUserAsync({ email, password, rememberMe: true });
            await emailVerifiedTagAsync({});
        },
    });

    return {
        loginUserWithEmail,
        isLoading: isLoading || isSetupLiveUserLoading || isLoginWithEmailPasswordLoading,
        isError: isError || isSetupLiveUserError || isLoginWithEmailError,
        ...props,
    };
};

export const useLogoutUser = () => {
    const queryClient = useQueryClient();

    const { mutate: logoutUser, ...props } = useMutation({
        mutationFn: logout,
        onSuccess: () => {
            ls.clear();
            queryClient.clear();
            axios.defaults.headers.Authorization = null;
        },
    });

    return { logoutUser, ...props };
};
export const useChangePassword = () => {
    const { mutate: changePassword, ...props } = useMutation({
        mutationFn: updatePassword,
    });

    return { changePassword, ...props };
};
