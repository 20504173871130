import { useEffect } from "react";

/**
 * Our custom hook to meet the functionality of useEffectOnce (deprecated) from 'usehooks-ts'.
*/
export const useEffectOnce = (func: ()=> void) => {
    useEffect(() => {
        func();
    }, []);
};
