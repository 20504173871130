import ls from "localstorage-slim";
import { useQuery, useQueryClient } from "react-query";
import { useCallback } from "react";

import { isDev } from "@/libs/processEnv";

const DEV_INFO_KEY = "dev-info";

type DevInfo = {
    version: string;
    authMode: "skip" | "strict";
    overlayPosition: { x: number; y: number };
};

export const DEFAULT_DEV_INFO: DevInfo = {
    version: "DEV",
    authMode: "strict",
    overlayPosition: { x: 0, y: 0 },
};

const getCurrentVersion = async () => {
    if (isDev()) {
        return DEFAULT_DEV_INFO.version;
    }

    const file = await fetch("/version.txt");
    const version = await file.text();

    return version || DEFAULT_DEV_INFO.version;
};

export const useDevInfo = () => {
    const queryClient = useQueryClient();

    const { data: devInfo = DEFAULT_DEV_INFO, ...props } = useQuery<DevInfo>(["dev"], {
        enabled: !isDev(),
        queryFn: async () => {
            const version = (await getCurrentVersion()).trim();
            const devInfo = ls.get<Omit<DevInfo, "version">>(DEV_INFO_KEY) ?? DEFAULT_DEV_INFO;

            return {
                ...devInfo,
                version,
            };
        },
    });

    const setMode = useCallback(
        <T>(key: keyof DevInfo, value: T) => {
            if (value !== devInfo[key]) {
                ls.set(DEV_INFO_KEY, { ...devInfo, [key]: value });
                queryClient.refetchQueries(["dev"]);
            }
        },
        [devInfo, queryClient]
    );

    const setAuthMode = useCallback((mode: DevInfo["authMode"]) => setMode("authMode", mode), [setMode]);

    const setOverlayPosition = useCallback(
        ({ x, y }: DevInfo["overlayPosition"]) => setMode("overlayPosition", JSON.stringify({ x, y })),
        [setMode]
    );
    return { devInfo, setAuthMode, setOverlayPosition, ...props };
};
