import type { AxiosError } from "axios";
import type { UseQueryOptions } from "react-query";
import type { IsDisabledProps } from "@/types";
import type {
    CurrentUserData,
    GetValidateMobileParams,
    HeadDoesEmailExistParams,
    UserCoreData,
    UserMetaData,
} from "./type";

import ls from "localstorage-slim";
import { useDebounce } from "use-debounce";
import { useQueryParams, StringParam } from "use-query-params";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { nonNullishObjectFormatter } from "@/libs/formatter";
import { isEU } from "@/libs/processEnv";
import { useCookie } from "@/libs/cookie";

import { useDemo } from "@/hooks/useDemo";

import { useUserAuth, useLoginWithEmailAndPassword } from "@/query/auth";

import {
    getCurrentUserCreateApplicant,
    getCurrentUserGenerateSumsubToken,
    getCurrentUserMetadata,
    getUserDepositedStatus,
    getValidateUserMobilePhone,
    headCheckEmailExists,
    postCreateNewDemoUser,
    postCreateNewLiveUser,
    postEmailResetPassword,
    postResendEmail,
    postSetupLegacyUser,
    putAcceptEulaLiveUser,
    putEditLiveUser,
    putSetupLiveUser,
    putEditDemoUser,
    sendOTP,
    verifyOTP,
    postEUCreateNewLiveUser,
    putEUEditLiveUser,
    EUAddAcknowledgement,
    getLookupItems,
    postEUElectiveProfessionalData,
    getElectiveProfessionalStatus,
    putEUSetupLiveUser,
    putEUDemoToLiveEditLiveUser,
    postEnableOtpOnLogin,
    addEmailVerifiedTag,
    manuallyApproveSumsub,
    getRecaptchaKey,
} from "./api";

/** Users-related Queries */
export const useCurrentUser = (
    options?: Omit<
        UseQueryOptions<CurrentUserData | undefined, unknown, CurrentUserData | undefined, (string | undefined)[]>,
        "queryKey"
    >
) => {
    const { auth, isLoading: isAuthLoading } = useUserAuth();
    const {
        data: user,
        isLoading,
        ...props
    } = useQuery(["user", auth?.uid], {
        ...options,
        /**
         * This is a restricted query, it is only enabled if authenticated.
         * It WILL result in an error without a valid accessToken anyway.
         * But double-stops are always better (also for performance).
         */
        enabled: !!auth && auth.emailVerified,
        queryFn: () => (auth ? getCurrentUserMetadata(auth) : undefined),
    });

    return { user, isLoading: isLoading || isAuthLoading, ...props };
};

export const useLiveUserDeposited = (
    options?: Omit<UseQueryOptions<boolean, unknown, boolean, string[]>, "queryKey">
) => {
    const queryClient = useQueryClient();
    const { user } = useCurrentUser();

    const { data: isLiveUserDeposited, ...props } = useQuery(["is-deposited"], {
        ...options,
        enabled: user?.role === "Live" && user?.isKycCompleted,
        refetchIntervalInBackground: true,
        refetchInterval: (data) => (data ? false : 6 * 1000),
        queryFn: getUserDepositedStatus,
        onSuccess: (isDeposited) => {
            /** Optimistically refetch live account list if status checks out. */
            if (isDeposited) {
                queryClient.refetchQueries(["live", "accounts", "te"]);
                queryClient.refetchQueries(["live", "accounts", "mt4"]);
            }
        },
    });

    return { isLiveUserDeposited, ...props };
};

export const useIsEmailRegistered = ({
    disabled = false,
    email: emailProps,
}: IsDisabledProps & HeadDoesEmailExistParams) => {
    const [email] = useDebounce(emailProps, 300, { trailing: true });
    const isEnabled = !(disabled || !email || email.length === 0);

    const { data: isEmailRegistered, ...props } = useQuery(["email-registered", email], {
        enabled: isEnabled,
        queryFn: () => headCheckEmailExists({ email }),
    });

    return { isEmailRegistered, ...props };
};

export const useMobilePhoneInfo = ({
    disabled = false,
    mobilePhone: mobilePhoneProps,
}: IsDisabledProps & GetValidateMobileParams) => {
    const [mobilePhone] = useDebounce(mobilePhoneProps, 300, { trailing: true });
    const isEnabled = !(disabled || !mobilePhone || mobilePhone.length === 0);

    const { data: mobilePhoneInfo, ...props } = useQuery(["mobile-phone", mobilePhone], {
        enabled: isEnabled,
        retry: (count, error) => count < 3 && (error as AxiosError).response?.status !== 400,
        queryFn: () => getValidateUserMobilePhone({ mobilePhone }),
    });

    return { mobilePhoneInfo, ...props };
};

export const useSumsubToken = (config?: IsDisabledProps) => {
    const { user } = useCurrentUser();

    const { data: sumsubToken, ...props } = useQuery(["user", "sumsub-token"], {
        enabled: !config?.disabled && !!user,
        queryFn: async () => {
            const tokenData = await getCurrentUserGenerateSumsubToken();
            const applicantId = await getCurrentUserCreateApplicant();

            return {
                ...tokenData,
                applicantId,
            };
        },
    });

    return { sumsubToken, ...props };
};

export const useUserAttribution = () => {
    const gpfxIBReferrer = "_gpfx_ib_referrer";
    const gpfxReferrer = "_gpfx_referrer";
    const gpfxLastReferrer = "_gpfx_last_referrer";
    const cookieValues = useCookie({ keys: [gpfxIBReferrer, gpfxReferrer, gpfxLastReferrer] });

    /** Retrieve additional tracking data from current page if possible. */
    const [queryString] = useQueryParams({
        clickId: StringParam,
        oib: StringParam,
        utmSource: StringParam,
        utmCampaign: StringParam,
        utmMedium: StringParam,
    });

    /** Clean up nullish attribution data. */
    const { clickId, oib, utmSource, utmCampaign, utmMedium } = nonNullishObjectFormatter<string>(
        queryString as Record<string, string>
    );
    return {
        clickId: cookieValues?.[gpfxIBReferrer] || clickId || undefined,
        gpfxReferrer: cookieValues?.[gpfxReferrer] || undefined,
        gpfxLastReferrer: cookieValues?.[gpfxLastReferrer] || undefined,
        oib,
        utmSource,
        utmCampaign,
        utmMedium,
    };
};

/** Common user mutations. */
export const useSendResetPasswordEmail = () => {
    const { mutate: sendResetPasswordEmail, ...props } = useMutation({
        mutationFn: postEmailResetPassword,
    });

    return { sendResetPasswordEmail, ...props };
};

export const useResendEmail = () => {
    const { mutate: resendEmail, ...props } = useMutation({
        mutationFn: postResendEmail,
    });

    return { resendEmail, ...props };
};

/** LIVE Users-related Mutations */
export const useCreateLiveUser = () => {
    const attribution = useUserAttribution();

    const { mutate: createLiveUser, ...props } = useMutation({
        mutationFn: (data: UserCoreData) => postCreateNewLiveUser({ ...data, ...attribution }),
        onSuccess: (_, { email, password }) => {
            /**expire in 6 hours - lifetime of firebase email link. */
            ls.set(email, password, { ttl: 21600, encrypt: true });
        },
    });

    return { createLiveUser, ...props };
};
export const useEUCreateLiveUser = () => {
    const attribution = useUserAttribution();

    const { mutate: createEULiveUser, ...props } = useMutation({
        mutationFn: (data: UserCoreData & UserMetaData) => postEUCreateNewLiveUser({ ...data, ...attribution }),
        onSuccess: (_, { email, password, mobilePhone }) => {
            /**expire in 6 hours - lifetime of firebase email link. */
            ls.set(email, password, { ttl: 21600, encrypt: true });
            ls.set(`${email}-EU`, mobilePhone, { ttl: 21600, encrypt: true });
        },
    });

    return { createEULiveUser, ...props };
};

export const useUpdateLiveUserInfo = () => {
    const queryClient = useQueryClient();

    const { mutate: updateLiveUserInfo, ...props } = useMutation({
        mutationFn: putEditLiveUser,
        onSuccess: async () => {
            ls.set("role-updated", "change");
            queryClient.refetchQueries(["user"]);
        },
    });

    return { updateLiveUserInfo, ...props };
};

export const useUpdateDemoToLiveUserInfo = () => {
    const queryClient = useQueryClient();

    const { mutate: updateDemoToLiveUserInfo, ...props } = useMutation({
        mutationFn: putEUDemoToLiveEditLiveUser,
        onSuccess: async () => {
            ls.set("role-updated", "change");
            queryClient.refetchQueries(["user"]);
        },
    });

    return { updateDemoToLiveUserInfo, ...props };
};

export const useEUUpdateLiveUserInfo = () => {
    const queryClient = useQueryClient();

    const { mutate: euUpdateLiveUserInfo, ...props } = useMutation({
        mutationFn: putEUEditLiveUser,
        onSuccess: () => queryClient.refetchQueries(["user"]),
    });

    return { euUpdateLiveUserInfo, ...props };
};

export const useEUAddClientAcknowledgement = () => {
    const queryClient = useQueryClient();

    const { mutate: euAddClientAcknowledgement, ...props } = useMutation({
        mutationFn: EUAddAcknowledgement,
        onSuccess: () => queryClient.refetchQueries(["user"]),
    });

    return { euAddClientAcknowledgement, ...props };
};

export const useAcceptEula = () => {
    const queryClient = useQueryClient();

    const { mutate: acceptEula, ...props } = useMutation({
        mutationFn: putAcceptEulaLiveUser,
        onSuccess: () => queryClient.refetchQueries(["user"]),
    });

    return { acceptEula, ...props };
};

export const useEmailVerifiedTag = () => {
    const queryClient = useQueryClient();

    const { mutate: emailVerifiedTag, ...props } = useMutation({
        mutationFn: addEmailVerifiedTag,
        onSuccess: () => queryClient.refetchQueries(["user"]),
    });

    return { emailVerifiedTag, ...props };
};

export const useSetupLiveUserAuth = () => {
    const queryClient = useQueryClient();

    const { mutate: setupLiveUser, ...props } = useMutation({
        mutationFn: isEU() ? putEUSetupLiveUser : putSetupLiveUser,
        onSuccess: () => queryClient.refetchQueries(["user"]),
    });

    return { setupLiveUser, ...props };
};

/** DEMO user mutations. */
export const useCreateDemoUser = () => {
    const attribution = useUserAttribution();

    const { mutate: createDemoUser, ...props } = useMutation({
        mutationFn: (data: Pick<UserCoreData, "email" | "country" | "password"> &
            Pick<UserMetaData, "firstName" | "lastName"> & { "region": string} 
        ) =>
            postCreateNewDemoUser({ ...data, ...attribution }),
        onSuccess: (_, { email, password }) => {
            /**expire in 6 hours - lifetime of firebase email link. */
            ls.set(email, password, { ttl: 21600, encrypt: true });
        },
    });

    return { createDemoUser, ...props };
};

export const useSetupDemoUserAuth = () => {
    const { user } = useCurrentUser();
    const { loginUser } = useLoginWithEmailAndPassword();

    const { mutate: setupDemoUser, ...props } = useMutation({
        mutationFn: putEditDemoUser,
        onSuccess: (_data, { password }) => {
            if (!user) {
                return;
            }

            /** Log user after successful registration. */
            loginUser({ email: user?.email, password });
        },
    });

    return { setupDemoUser, ...props };
};

export const useSetupLegacyUser = () => {
    const { mutate: setupLegacyUser, ...props } = useMutation({
        mutationFn: postSetupLegacyUser,
    });

    return { setupLegacyUser, ...props };
};

export const useSendOtp = () => {
    const { mutate: sendOtp, ...props } = useMutation({
        mutationFn: sendOTP,
    });

    return { sendOtp, ...props };
};

export const useVerifyOtp = () => {
    const { mutate: verifyOtp, ...props } = useMutation({
        mutationFn: verifyOTP,
    });

    return { verifyOtp, ...props };
};

export const useTradingLookupItems = () => {
    const { data: { allTradingOptions } = { allTradingOptions: [] }, ...props } = useQuery(["tradingOptions"], {
        queryFn: async () => {
            const resp = await getLookupItems();
            const allTradingOptions = resp.filter(
                (item) => item.code !== "financialSector" && item.code !== "financialSectorPosition"
            );
            return {
                allTradingOptions,
            };
        },
    });
    return { allTradingOptions, ...props };
};

export const useElectiveProfessionalLookupItems = () => {
    const { data: { electiveProfessionalLookups } = { electiveProfessionalLookups: [] }, ...props } = useQuery(
        ["electiveProfessionalLookups"],
        {
            queryFn: async () => {
                const resp = await getLookupItems();
                const electiveProfessionalLookups = resp.filter(
                    (item) => item.code === "financialSector" || item.code === "financialSectorPosition"
                );
                return {
                    electiveProfessionalLookups,
                };
            },
        }
    );
    return { electiveProfessionalLookups, ...props };
};

export const useElectiveProfessionalStatus = () => {
    const { isKycPending } = useDemo();
    const { data, ...props } = useQuery(["electiveProfessionalStatus"], {
        refetchInterval: (data) =>
            !isEU() || isKycPending || data?.electiveProfessionalStatus !== 1 ? false : 5 * 1000,
        enabled: isEU(),
        queryFn: async () => {
            const electiveProfessionalStatus = await getElectiveProfessionalStatus();
            return {
                electiveProfessionalStatus,
            };
        },
    });
    return { data, ...props };
};

export const useElectiveProfessionalSubmitProofs = () => {
    const queryClient = useQueryClient();

    const { mutate: electiveProfessionalSubmission, ...props } = useMutation({
        mutationFn: postEUElectiveProfessionalData,
        onSuccess: () => {
            queryClient.refetchQueries(["electiveProfessionalStatus"]);
        },
    });

    return { electiveProfessionalSubmission, ...props };
};

export const useEnableOTPOnLogin = () => {
    const queryClient = useQueryClient();

    const { mutate: enableOTP, ...props } = useMutation({
        mutationFn: postEnableOtpOnLogin,
        onSuccess: () => {
            queryClient.refetchQueries(["user"]);
        },
    });

    return { enableOTP, ...props };
};

export const useSumsubManually = () => {
    const { mutate: approveSumsubManually, ...props } = useMutation({
        mutationFn: manuallyApproveSumsub,
    });

    return { approveSumsubManually, ...props };
};

export const useRecaptchaToken = () => {
    const { data: recaptchaKey, ...props } = useQuery(["recaptcha-key"], {
        queryFn: () => getRecaptchaKey(),
    });

    return { recaptchaKey, ...props };
};
