import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCountdown } from "usehooks-ts";

import { isDev } from "@/libs/processEnv";

import { useEffectOnce } from "@/hooks/useEffectOnce";

import { useLogoutUser } from "@/query/auth";

import { AppLayout } from "@/features/appLayout";

import { AprButton } from "@/components/Button";
import { AprSpinner } from "@/components/Spinner";
import { AprPortal } from "@/components/Overlay";

const DEFAULT_TIMEOUT_COUNTDOWN = 30;

export const LoadingLayout = () => {
    const navigate = useNavigate();
    const { logoutUser } = useLogoutUser();

    const [count, { startCountdown }] = useCountdown({ countStart: DEFAULT_TIMEOUT_COUNTDOWN });

    const handleNavigateToLogin = useCallback(() => {
        logoutUser(undefined, {
            onSettled: () => navigate("/login", { state: { force: true } }),
        });
    }, [logoutUser, navigate]);

    useEffectOnce(startCountdown);

    useEffect(() => {
        if (count === 0) {
            handleNavigateToLogin();
        }
    }, [count, handleNavigateToLogin]);

    return (
        <AppLayout>
            <div className="flex min-h-screen w-full flex-1">
                <AprSpinner className="m-auto h-8 w-8" aria-label="Loading Afterprime Client Portal" />
                {/** TODO: Ask designer for a more meaningful loading state here. */}
                {count <= (isDev() ? DEFAULT_TIMEOUT_COUNTDOWN : DEFAULT_TIMEOUT_COUNTDOWN / 2) && (
                    <AprPortal>
                        <AprButton
                            variant="tertiary"
                            onClick={handleNavigateToLogin}
                            className="fixed bottom-4 left-1/2 -translate-x-1/2"
                        >
                            Go to home page ({count})
                        </AprButton>
                    </AprPortal>
                )}
            </div>
            <AppLayout.Footer showfooter={"true"} />
        </AppLayout>
    );
};
